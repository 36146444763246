var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flip-card col-12 col-sm-6 col-md-4 col-lg-3"},[_c('div',{staticClass:"flip-card-inner"},[_c('div',{staticClass:"flip-card-front"},[_c('img',{attrs:{"src":_vm.imgSrc,"onerror":("this.src='" + _vm.defaultImg + "'"),"alt":"Image"}}),_c('div',{staticClass:"flip-card-title"},[_c('h4',{staticClass:"cut-1line",attrs:{"title":_vm.employeeName}},[_vm._v(_vm._s(_vm.employeeName))])]),_c('span',{staticClass:"employee-status",class:_vm.employeeStatus})]),_c('div',{staticClass:"flip-card-back"},[_c('div',{staticClass:"flip-card-title-top"},[_c('h6',{staticClass:"cut-1line"},[_vm._v(_vm._s(_vm.employeeData.employeeCode))])]),_c('div',{staticClass:"flip-card-back-icons"},[_c('router-link',{attrs:{"to":{
            name: 'EmployeeInfo',
            params: {
              employeeToken: _vm.employeeData.employeeToken,
            },
          },"title":_vm.$t('info')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/info.svg")}})]),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeesAttendance.employeesAttendanceViewAll
            )
          )?_c('router-link',{attrs:{"to":'/attendance-employee/' + _vm.employeeData.employeeToken,"title":_vm.$t('employeeHistory')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/attendance.svg")}})]):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeeSalarySetting.employeeSalarySettingView
            )
          )?_c('router-link',{attrs:{"to":'/employee-salary-settings/' + _vm.employeeData.employeeToken,"title":_vm.$t('EmployeeSalarySetting')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/employee-salary-setting.svg")}})]):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeeWorkSchedule.employeeWorkScheduleView
            )
          )?_c('router-link',{attrs:{"to":'/employee-work-schedules/' + _vm.employeeData.employeeToken,"title":_vm.$t('EmployeeWorkSchedule')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/work-schedule.svg")}})]):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
                .additionalSettingsView
            )
          )?_c('router-link',{attrs:{"to":'/employee-official-holidays-plans/' + _vm.employeeData.employeeToken,"title":_vm.$t('EmployeeOfficialHolidaysPlans')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/holiday.svg")}})]):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployees
                .employeesUpdate
            )
          )?_c('router-link',{attrs:{"to":{
            name: 'EmployeeEdit',
            params: {
              employeeToken: _vm.employeeData.employeeToken,
            },
          },"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})]):_vm._e(),_c('router-link',{attrs:{"to":{
            name: 'EmployeeBalancesUseds',
            params: {
              employeeToken: _vm.employeeData.employeeToken,
            },
          },"title":_vm.$t('EmployeeBalancesUseds.modelName')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/balances-plans.svg")}})]),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployees
                .employeesArchive
            )
          )?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EmployeeDelete",modifiers:{"EmployeeDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":_vm.setEmployeeData}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})]):_vm._e()],1),_c('div',{staticClass:"flip-card-title"},[_c('h4',{staticClass:"cut-1line",attrs:{"title":_vm.employeeName}},[_vm._v(_vm._s(_vm.employeeName))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }